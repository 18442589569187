<template lang="pug">
#app(v-cloak, :class="({ 'show-nav': isSidebarMenuVisible }, backgroundColorClass)")
  BaseHeader(v-if="isReady")
  main
    BaseSidebar(v-if="isReady && isSidebarVisible")
    IncompleteProfile(v-if="showCompleteProfile")
    Popup(
      :visible="error !== ''",
      closeButton,
      closeOutside,
      ok="Ok",
      :height="errorHeight",
      :title="errorTitle",
      @hiding="resetError"
    )
      p {{ error }}
    Popup(
      :visible="popupText !== ''",
      closeButton,
      closeOutside,
      ok="Ok",
      :title="popupTitle",
      @hiding="resetPopupText"
    )
      p {{ popupText }}

    router-view(v-if="isReady") 
  BaseFooter(v-if="isReady && isFooterVisible")
  SessionTimeout
  TILoader(v-if="showTiLoader")
</template>

<script lang="ts">
import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import { mapState, mapMutations } from "vuex";
import BaseFooter from "@/components/Footer/BaseFooter.vue";
import BaseHeader from "@/components/Header/BaseHeader.vue";
import BaseSidebar from "@/components/Sidebar/BaseSidebar.vue";
import { BasePopup as Popup } from "@cruciallearning/puddle";
import SessionTimeout from "@/components/DialogueBoxes/SessionTimeout.vue";
import TILoader from "./components/OnDemand/TILoader.vue";
import IncompleteProfile from "./components/Auth/IncompleteProfile.vue";

@Component({
  components: { BaseHeader, BaseSidebar, BaseFooter, Popup, SessionTimeout, TILoader, IncompleteProfile },
  computed: {
    ...mapState(["backgroundColorClass", "isSidebarVisible", "isFooterVisible", "sidebarType", "isReady"]),
    ...mapState("BaseModule", ["error", "errorTitle", "errorHeight", "popupText", "popupTitle"]),
    ...mapState("OnDemandModule", ["showTiLoader"]),
  },
  methods: {
    ...mapMutations("BaseModule", ["resetError", "resetPopupText"]),
  },
})
export default class App extends Vue {
  @ProvideReactive() toggleSidebarCallback = this.toggleSidebar;

  private isSidebarMenuVisible = false;
  private readonly error!: string;
  private readonly resetError!: () => void;
  toggleSidebar(isVisible: boolean): void {
    this.isSidebarMenuVisible = isVisible;
  }
  get showCompleteProfile(): boolean {
    return (
      this.$auth.authUser.token != "NONE" &&
      (!this.$auth.authUser.given_name?.trim() || !this.$auth.authUser.family_name?.trim())
    );
  }
}
</script>
