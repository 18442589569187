<template lang="pug">
div
  header.site-masthead.container
    .overlay(@click="closeSidebar")
    .site-logo
      router-link(to="/events", data-name="menu-logo", @click.native="hideSubmenu()")
        img(:src="logoSrc", alt="Crucial Learning Logo")

    .open-nav(@click="toggleSidebar")
      Icon(value="menu")
    nav.header-nav(v-if="!sparseMenu")
      ul.list-unstyled
        li(v-for="item in authorizedItems", :id="`target-${item.name}`")
          div(v-if="item.submenu", @mouseenter="showSubmenu(item.name)", @mouseleave="hideSubmenu()")
            router-link.menu-with-submenu(
              :to="item.route",
              :data-name="`menu-${item.name.toLowerCase()}`",
              @click.native="hideSubmenu()"
            ) {{ item.title }}
              Icon.menu-item-chevron(:value="submenuChevron(item.name)", :color="submenuChevronColor(item.name)")
            PopoverMenu.submenu-container(
              :target="`#target-${item.name}`",
              :visible="isSubmenuVisible(item.name)",
              target-position="bottom left",
              popover-position="top left",
              :y=-7
            )
              BaseSubmenu(
                :menu="item.submenu",
                :target="`#target-${item.name}`",
                @mouseenter="showSubmenu(item.name)",
                @mouseleave="hideSubmenu()",
                @click="hideSubmenu()"
              )
          router-link.menu-item(v-else, :to="item.route", :data-name="`menu-${item.name.toLowerCase()}`") {{ item.title }}
        li
          a.menu-item(
            v-if="$auth.FACILITATOR",
            style="cursor: pointer",
            @click.stop.prevent="$api.event.openSdiWindow($event); return false"
          ) SDI
    UserMenu(v-if="$auth.isAuthenticated && !sparseMenu")
    SystemStatus
  DrawerMenu(:opened="openDrawer", :menuItems="authorizedItems", @selected="closeSidebar")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import UserMenu from "@/components/Header/User/UserMenu.vue";
import SystemStatus from "@/components/SystemStatus/SystemStatus.vue";
import { BaseAuthModel, NavigationItem } from "@/store/modules/Base/model";
import Util from "@/auth/Util";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";

import clLogoWhite from "@/assets/images/logos/cl-logo-white.svg";
import clMark from "@/assets/images/logos/cl-mark.svg";
import DrawerMenu from "@/components/Sidebar/DrawerMenu.vue";
import Icon from "@/components/Icons/Icon.vue";
import BaseSubmenu from "@/components/Header/BaseSubmenu.vue";

@Component({
  components: {
    BaseSubmenu,
    DrawerMenu,
    UserMenu,
    SystemStatus,
    Icon,
    PopoverMenu,
  },
  computed: {
    ...mapGetters("BaseModule", ["getMenuOnlyNavigationItems"]),
    ...mapGetters("SdiModule", ["getSdiPlatformLinkHref", "getSdiPlatformLinkTarget"]),
  },
})
export default class DarkHeader extends Vue {
  private readonly getMenuOnlyNavigationItems!: NavigationItem[];

  private sparseMenu = this.$route.path === "/system-check";
  private openDrawer = false;

  private submenuVisible = "";
  private submenuHideRequest = false;

  get logoSrc(): string {
    return this.sparseMenu ? clLogoWhite : clMark;
  }

  get authorizedItems(): NavigationItem[] {
    if (this.getMenuOnlyNavigationItems) {
      return this.getMenuOnlyNavigationItems.filter((e) => this.isAuthorized(e.auth));
    }
    return [];
  }

  toggleSidebar(): void {
    this.openDrawer = !this.openDrawer;
  }

  closeSidebar(): void {
    this.openDrawer = false;
  }

  get isAuthorized(): (auth: BaseAuthModel | undefined) => boolean {
    return (auth: BaseAuthModel | undefined) => {
      if (!auth) return false;
      return Util.isAuthorized(auth);
    };
  }

  isSubmenuVisible(name: string) {
    return this.submenuVisible === name;
  }

  showSubmenu(name: string) {
    this.submenuHideRequest = false;
    this.submenuVisible = name;
  }

  hideSubmenu(): void {
    // Debounce show/hide requests
    this.submenuHideRequest = true;
    setTimeout(this.doHideSubmenu, 50);
  }
  private doHideSubmenu(): void {
    if (this.submenuHideRequest) {
      this.submenuVisible = "";
      this.submenuHideRequest = false;
    }
  }

  submenuChevron(name: string): string {
    return this.submenuVisible === name ? "chevron-up" : "chevron-down";
  }
  submenuChevronColor(name: string): string {
    return this.submenuVisible === name ? "var(--gray-30)" : "white";
  }
}
</script>
